import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Nimble</title>
        <meta property="og:title" content="Nimble" />
      </Helmet>
      <div className="home-container01">
        <div className="home-container02">
          <div className="home-container03">
            <span className="home-text">Nimble</span>
            <div className="home-container04">
              <h1 className="home-text01">
                <span>
                  Under
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>Construction</span>
                <br></br>
              </h1>
              <span className="home-text06">
                <span className="home-text07">
                  Its time for a technology upgrade.
                </span>
                <br className="home-text08"></br>
                <span className="home-text09">Nimble, aims to </span>
                <span className="home-text10">
                  bring financial technology to you.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text11">Saving you time and money.</span>
                <br className="home-text12"></br>
              </span>
              <div className="home-container05">
                <input
                  type="text"
                  id="notify-me-input"
                  placeholder="Enter your email"
                  className="home-input input"
                />
                <button
                  id="notify-me-submit-button"
                  type="button"
                  className="home-button button"
                >
                  Notify Me
                </button>
              </div>
              <span className="home-text13">
                <span>
                  If you want a financial service built for you, that moves at
                  your pace.
                </span>
                <br></br>
                <span>Leave your email for more update!</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
        <div className="home-container06">
          <div className="home-container07">
            <div className="home-container08">
              <div className="home-container09">
                <h1 className="home-text18">What&apos;s To Come?</h1>
                <span className="home-text19">
                  At Nimble, we aim to modernize your financial journey. As the
                  first region specific financial service, we strive to ensure
                  your interactions are as swift and seamless as possible. We
                  are currently working on:
                </span>
              </div>
              <div className="home-container10">
                <h1 className="home-text20">Online Application</h1>
                <span className="home-text21">
                  Apply for your Nimble account and be verified and active in as
                  little as 3 minutes. Access your funds and manage your money
                  quickly and efficiently. Finances has never been so easy.
                </span>
              </div>
              <div className="home-container11">
                <h1 className="home-text22">Quick and Easy Transfers</h1>
                <span className="home-text23">
                  Enjoy sending money regionally with zero transfer fees, and
                  take advantage of competitive rates for global transfers.
                  Whether personal or business, Nimble ensures that your
                  transactions are affordable and hassle-free.All from the
                  comfort of your own home.
                </span>
              </div>
              <div className="home-container12">
                <h1 className="home-text24">Helping You Retire</h1>
                <span className="home-text25">
                  Access the global markets like never before with your Nimble
                  account allowing you to access the world&apos;s market you
                  seamlessly.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
