import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './j-ob-ca-rd-s.css'

const JObCaRdS = (props) => {
  return (
    <div className={`j-ob-ca-rd-s-container ${props.rootClassName} `}>
      <div className="j-ob-ca-rd-s-container1">
        <div className="j-ob-ca-rd-s-content thq-flex-column">
          <h3 className="j-ob-ca-rd-s-job-title thq-heading-3">
            HUMAN RESOURCES MANAGER
          </h3>
          <div className="j-ob-ca-rd-s-container2">
            <span className="thq-body-small">Nimble Bank</span>
            <span className="j-ob-ca-rd-s-location">
              Onsite | Rodney Bay St. Lucia
            </span>
          </div>
          <Link to="/hr-job-opening" className="j-ob-ca-rd-s-navlink button">
            Apply Now
          </Link>
        </div>
        <div className="j-ob-ca-rd-s-content1 thq-flex-column">
          <h3 className="j-ob-ca-rd-s-job-title1 thq-heading-3">
            <span className="">INTERNAL AUDITOR</span>
            <br className=""></br>
            <br className=""></br>
          </h3>
          <div className="j-ob-ca-rd-s-container3">
            <span className="thq-body-small">Nimble Bank</span>
            <span className="j-ob-ca-rd-s-location1">
              Onsite | Rodney Bay St. Lucia
            </span>
          </div>
          <Link
            to="/internal-auditor-job-opening"
            className="j-ob-ca-rd-s-navlink1 button"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  )
}

JObCaRdS.defaultProps = {
  text2: 'Submit Application',
  text: 'Apply Now',
  text1: 'Submit Application',
  rootClassName: '',
  apply: 'Apply Now',
}

JObCaRdS.propTypes = {
  text2: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  apply: PropTypes.string,
}

export default JObCaRdS
