import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './compliance-officer-job-opening.css'

const ComplianceOfficerJobOpening = (props) => {
  return (
    <div className="compliance-officer-job-opening-container">
      <Helmet>
        <title>Compliance-Officer-Job-Opening - Nimble</title>
        <meta
          property="og:title"
          content="Compliance-Officer-Job-Opening - Nimble"
        />
      </Helmet>
      <div className="compliance-officer-job-opening-container01">
        <div className="compliance-officer-job-opening-container02">
          <div className="compliance-officer-job-opening-container03">
            <div className="compliance-officer-job-opening-container04">
              <Link to="/" className="compliance-officer-job-opening-navlink">
                Nimble
              </Link>
            </div>
            <div className="compliance-officer-job-opening-container05">
              <span className="compliance-officer-job-opening-text">
                About Us
              </span>
              <Link
                to="/careers"
                className="compliance-officer-job-opening-navlink1"
              >
                Careers
              </Link>
            </div>
          </div>
        </div>
        <div className="compliance-officer-job-opening-container06">
          <div className="compliance-officer-job-opening-container07">
            <div className="compliance-officer-job-opening-container08">
              <h1 className="compliance-officer-job-opening-text01">TAKE </h1>
              <h1 className="compliance-officer-job-opening-text02">THE </h1>
              <h1 className="compliance-officer-job-opening-text03">NEXT </h1>
              <h1 className="compliance-officer-job-opening-text04">STEP.</h1>
            </div>
            <h1 className="compliance-officer-job-opening-text05">
              <span>Join the Nimble Team</span>
              <br></br>
            </h1>
          </div>
          <div className="compliance-officer-job-opening-container09">
            <h1 className="compliance-officer-job-opening-text08">
              <span className="compliance-officer-job-opening-text09">
                Compliance Officer
              </span>
              <br></br>
            </h1>
            <div className="compliance-officer-job-opening-container10">
              <span className="compliance-officer-job-opening-text11">
                Onsite | Rodney Bay, St. Lucia
              </span>
              <a
                href="https://forms.gle/KUsbiiHxFA9VUSHh6"
                target="_blank"
                rel="noreferrer noopener"
                className="compliance-officer-job-opening-link button"
              >
                <span className="compliance-officer-job-opening-text12">
                  <span>Submit Application</span>
                  <br></br>
                </span>
              </a>
            </div>
            <h1 className="compliance-officer-job-opening-text15">About Us</h1>
            <span className="compliance-officer-job-opening-text16">
              Nimble is a forward-thinking company in the process of applying
              for a banking license, and we are excited about the transformative
              journey ahead. We believe in the power of adaptability,
              technology, and exceptional service delivery. We are looking for a
              passionate and experienced Human Resources Manager to join our
              team and build our HR department from the ground up. This is a
              unique opportunity to create your dream department and be a
              pivotal part of our growth
            </span>
            <h1 className="compliance-officer-job-opening-text17">Note:</h1>
            <span className="compliance-officer-job-opening-text18">
              <span>
                This job offer is subject to ECCB licensing. The successful
                candidate will play a crucial role in helping us achieve our
                banking license and will be a key member of our leadership team.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                If you are a visionary HR professional looking for a unique
                opportunity to shape the future of HR at a dynamic company, we
                would love to hear from you. Join us at Nimble, and be part of
                our journey of growth and innovation in the region, helping
                serve the underserved and banking the unbanked.
              </span>
              <br></br>
              <br></br>
              <span>
                Your application is completely confidential. However, we will
                have to share your information with the Eastern Caribbean
                Central Bank.
              </span>
            </span>
            <h1 className="compliance-officer-job-opening-text26">Role:</h1>
            <span className="compliance-officer-job-opening-text27">
              As the Risk/Compliance Officer at Nimble, you will be responsible
              for developing, implementing, and managing our risk management and
              compliance programs. You will play a crucial role in identifying
              and mitigating risks, ensuring compliance with regulatory
              requirements, and promoting a culture of integrity and ethical
              behavior within the organization. This position requires a
              detail-oriented and analytical individual who is comfortable with
              technology and willing to learn and use Google Workspace
              effectively.
            </span>
            <h1 className="compliance-officer-job-opening-text28">
              Responsibilities:
            </h1>
            <span className="compliance-officer-job-opening-text29">
              <span>
                • Develop and implement comprehensive risk management and
                compliance programs
              </span>
              <br></br>
              <span>
                • Conduct risk assessments and identify areas of potential
                compliance vulnerability and risk
              </span>
              <br></br>
              <span>
                • Ensure the company complies with all relevant laws,
                regulations, and internal policies
              </span>
              <br></br>
              <span>
                • Monitor and report on the effectiveness of the risk management
                and compliance programs
              </span>
              <br></br>
              <span>
                • Provide guidance and training to employees on
                compliance-related matters
              </span>
              <br></br>
              <span>
                • Investigate and resolve compliance issues and incidents
              </span>
              <br></br>
              <span>
                • Maintain and update documentation related to compliance and
                risk management activities
              </span>
              <br></br>
              <span>
                • Stay up-to-date with changes in regulatory requirements and
                best practices
              </span>
              <br></br>
              <span>
                • Collaborate with various departments to ensure a consistent
                approach to risk management and compliance
              </span>
              <br></br>
              <span>
                • Prepare and present reports to senior management and the board
                on the status of the risk management and compliance programs
              </span>
              <br className="compliance-officer-job-opening-text49"></br>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
            <h1 className="compliance-officer-job-opening-text53">
              Requirements:
            </h1>
            <span className="compliance-officer-job-opening-text54">
              <span>
                • Proven working experience as a Risk/Compliance Officer or
                similar role
              </span>
              <br></br>
              <span>
                • In-depth knowledge of industry standards and regulations,
                particularly in the banking sector
              </span>
              <br></br>
              <span>• Strong analytical and problem-solving skills</span>
              <br></br>
              <span>• Excellent written and verbal communication skills</span>
              <br></br>
              <span>
                • High attention to detail and the ability to work independently
              </span>
              <br></br>
              <span>
                • Comfortable with technology and computer usage or willing to
                enthusiastically learn, particularly Google Workspace
              </span>
              <br></br>
              <span>
                • High self-accountability and the ability to work independently
                without micromanagement
              </span>
              <br></br>
              <span>
                • Willingness to relocate to St. Lucia or currently reside in
                St. Lucia
              </span>
              <br></br>
              <span>
                • Candidates with 10+ years of direct banking experience will be
                preferential
              </span>
              <br></br>
            </span>
            <h1 className="compliance-officer-job-opening-text73">Salary:</h1>
            <span className="compliance-officer-job-opening-text74">
              <span>• $150,000 - $180,000 XCD base</span>
              <br></br>
              <span>• Plus s</span>
              <span>igning bonus</span>
              <br></br>
            </span>
            <h1 className="compliance-officer-job-opening-text80">Benefits:</h1>
            <span className="compliance-officer-job-opening-text81">
              <span>• </span>
              <span>Flexible PTO</span>
              <br></br>
              <span>• </span>
              <span>Flexibility to work from home</span>
              <br></br>
              <span>• </span>
              <span>Health, Vision, Dental Insurance</span>
              <br></br>
              <span>• </span>
              <span>Support and autonomy to build your dream department</span>
              <br></br>
            </span>
            <div className="compliance-officer-job-opening-footer">
              <a
                href="https://forms.gle/KUsbiiHxFA9VUSHh6"
                target="_blank"
                rel="noreferrer noopener"
                className="compliance-officer-job-opening-link1 button"
              >
                <span className="compliance-officer-job-opening-text94">
                  <span>Submit Application</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="compliance-officer-job-opening-container11">
          <div className="compliance-officer-job-opening-container12">
            <div className="compliance-officer-job-opening-container13">
              <Link to="/" className="compliance-officer-job-opening-navlink2">
                Nimble
              </Link>
            </div>
            <div className="compliance-officer-job-opening-container14">
              <span className="compliance-officer-job-opening-text97">
                About Us
              </span>
              <Link
                to="/careers"
                className="compliance-officer-job-opening-navlink3"
              >
                Careers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComplianceOfficerJobOpening
