import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Page from './views/page'
import InternalAuditorJobOpening from './views/internal-auditor-job-opening'
import Home from './views/home'
import Careers from './views/careers'
import ComplianceOfficerJobOpening from './views/compliance-officer-job-opening'
import HRJobOpening from './views/hr-job-opening'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Page} exact path="/page" />
        <Route
          component={InternalAuditorJobOpening}
          exact
          path="/internal-auditor-job-opening"
        />
        <Route component={Home} exact path="/" />
        <Route component={Careers} exact path="/careers" />
        <Route
          component={ComplianceOfficerJobOpening}
          exact
          path="/compliance-officer-job-opening"
        />
        <Route component={HRJobOpening} exact path="/hr-job-opening" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
