import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar4 from '../components/navbar4'
import Hero9 from '../components/hero9'
import Features1 from '../components/features1'
import Contact3 from '../components/contact3'
import Footer1 from '../components/footer1'
import './page.css'

const Page = (props) => {
  return (
    <div className="page-container">
      <Helmet>
        <title>Page - Nimble</title>
        <meta property="og:title" content="Page - Nimble" />
      </Helmet>
      <div className="page-navbar1">
        <Navbar4></Navbar4>
      </div>
      <div className="page-hero2">
        <Hero9></Hero9>
      </div>
      <div className="page-features3">
        <Features1></Features1>
      </div>
      <div className="page-contact4">
        <Contact3></Contact3>
      </div>
      <div className="page-footer5">
        <Footer1></Footer1>
      </div>
    </div>
  )
}

export default Page
