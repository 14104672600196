import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hr-job-opening.css'

const HRJobOpening = (props) => {
  return (
    <div className="hr-job-opening-container">
      <Helmet>
        <title>HR-Job-Opening - Nimble</title>
        <meta property="og:title" content="HR-Job-Opening - Nimble" />
      </Helmet>
      <div className="hr-job-opening-container01">
        <div className="hr-job-opening-container02">
          <div className="hr-job-opening-container03">
            <div className="hr-job-opening-container04">
              <Link to="/" className="hr-job-opening-navlink">
                Nimble
              </Link>
            </div>
            <div className="hr-job-opening-container05">
              <span className="hr-job-opening-text">About Us</span>
              <Link to="/careers" className="hr-job-opening-navlink1">
                Careers
              </Link>
            </div>
          </div>
        </div>
        <div className="hr-job-opening-container06">
          <div className="hr-job-opening-container07">
            <div className="hr-job-opening-container08">
              <h1 className="hr-job-opening-text001">TAKE </h1>
              <h1 className="hr-job-opening-text002">THE </h1>
              <h1 className="hr-job-opening-text003">NEXT </h1>
              <h1 className="hr-job-opening-text004">STEP.</h1>
            </div>
            <h1 className="hr-job-opening-text005">
              <span>Join the Nimble Team</span>
              <br></br>
            </h1>
          </div>
          <div className="hr-job-opening-container09">
            <h1 className="hr-job-opening-text008">Human Resources Manager</h1>
            <div className="hr-job-opening-container10">
              <span className="hr-job-opening-text009">
                Onsite | Rodney Bay, St. Lucia
              </span>
              <a
                href="https://forms.gle/tARvBEYprcRCQsph6"
                target="_blank"
                rel="noreferrer noopener"
                className="hr-job-opening-link button"
              >
                <span className="hr-job-opening-text010">
                  <span>Submit Application</span>
                  <br></br>
                </span>
              </a>
            </div>
            <h1 className="hr-job-opening-text013">About Us</h1>
            <span className="hr-job-opening-text014">
              Nimble is a forward-thinking company in the process of applying
              for a banking license, and we are excited about the transformative
              journey ahead. We believe in the power of adaptability,
              technology, and exceptional service delivery. We are looking for a
              passionate and experienced Human Resources Manager to join our
              team and build our HR department from the ground up. This is a
              unique opportunity to create your dream department and be a
              pivotal part of our growth
            </span>
            <h1 className="hr-job-opening-text015">Note:</h1>
            <span className="hr-job-opening-text016">
              <span>
                This job offer is subject to ECCB licensing. The successful
                candidate will play a crucial role in helping us achieve our
                banking license and will be a key member of our leadership team.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                If you are a visionary HR professional looking for a unique
                opportunity to shape the future of HR at a dynamic company, we
                would love to hear from you. Join us at Nimble, and be part of
                our journey of growth and innovation in the region, helping
                serve the underserved and banking the unbanked.
              </span>
              <br></br>
              <br></br>
              <span>
                Your application is completely confidential. However, we will
                have to share your information with the Eastern Caribbean
                Central Bank.
              </span>
            </span>
            <h1 className="hr-job-opening-text024">Role:</h1>
            <span className="hr-job-opening-text025">
              As the Human Resources Manager at Nimble, you will have the
              autonomy and support to establish and develop our HR department.
              You will be responsible for creating and implementing HR
              strategies, policies, and programs that align with our company’s
              vision and goals. This role requires a self-motivated and
              accountable individual who is enthusiastic about integrating
              technology into HR processes and willing to learn and use Google
              Workspace effectively.
            </span>
            <h1 className="hr-job-opening-text026">Responsibilities:</h1>
            <span className="hr-job-opening-text027">
              <span>
                • Develop and implement HR strategies and initiatives aligned
                with the overall business strategy
              </span>
              <br></br>
              <span>• </span>
              <span>
                Build the HR department from scratch, including recruitment,
                onboarding, performance management, employee relations, and
                compliance
              </span>
              <br></br>
              <span>• </span>
              <span>
                Foster a positive and inclusive workplace culture that promotes
                employee engagement and satisfaction
              </span>
              <br></br>
              <span>• </span>
              <span>
                Ensure legal compliance throughout human resource management
              </span>
              <br></br>
              <span>• </span>
              <span>
                Manage the recruitment and selection process to attract top
                talent
              </span>
              <br></br>
              <span>• </span>
              <span>
                Develop and monitor overall HR strategies, systems, tactics, and
                procedures across the organization
              </span>
              <br></br>
              <span>• </span>
              <span>
                Oversee and manage a performance appraisal system that drives
                high performance
              </span>
              <br></br>
              <span>• </span>
              <span>Maintain pay plan and benefits program</span>
              <br></br>
              <span>• </span>
              <span>
                Assess training needs to apply and monitor training programs
              </span>
              <br></br>
              <span>• </span>
              <span>
                Report to management and provide decision support through HR
                metrics
              </span>
              <br></br>
              <span>• </span>
              <span>
                Ensure the HR department’s operations will be legally compliant
                and aligned with our business goals
              </span>
              <br></br>
              <span>• </span>
              <span>
                Proactively identify and address employee issues and concerns
              </span>
              <br></br>
            </span>
            <h1 className="hr-job-opening-text063">Requirements:</h1>
            <span className="hr-job-opening-text064">
              <span>
                • Proven working experience as an HR Manager or other HR
                Executive
              </span>
              <br></br>
              <span>• </span>
              <span>People-oriented and results-driven</span>
              <br></br>
              <span>• </span>
              <span>Demonstrable experience with Human Resources metrics</span>
              <br></br>
              <span>• </span>
              <span>Knowledge of HR systems and databases</span>
              <br></br>
              <span>• </span>
              <span>
                Ability to architect strategy along with leadership skills
              </span>
              <br></br>
              <span>• </span>
              <span>
                Excellent active listening, negotiation, and presentation skills
              </span>
              <br></br>
              <span>• </span>
              <span>
                Competence to build and effectively manage interpersonal
                relationships at all levels of the company
              </span>
              <br></br>
              <span>• </span>
              <span>In-depth knowledge of labor law and HR best practices</span>
              <br></br>
              <span>• </span>
              <span>
                Comfortable with technology and computer usage or willing to
                enthusiastically learn, particularly Google Workspace
              </span>
              <br></br>
              <span>• </span>
              <span>
                High self-accountability and the ability to work independently
                without micromanagement
              </span>
              <br></br>
              <span>• </span>
              <span>
                Willing to relocate to St. Lucia or currently reside in St.
                Lucia
              </span>
              <br></br>
              <span>• </span>
              <span>
                Candidates with 10+ years of direct banking experience will be
                preferential
              </span>
              <br></br>
            </span>
            <h1 className="hr-job-opening-text100">Salary:</h1>
            <span className="hr-job-opening-text101">
              <span>• $150,000 - $180,000 XCD base</span>
              <br></br>
              <span>• Plus s</span>
              <span>igning bonus</span>
              <br></br>
            </span>
            <h1 className="hr-job-opening-text107">Benefits:</h1>
            <span className="hr-job-opening-text108">
              <span>• </span>
              <span>Flexible PTO</span>
              <br></br>
              <span>• </span>
              <span>Flexibility to work from home</span>
              <br></br>
              <span>• </span>
              <span>Health, Vision, Dental Insurance</span>
              <br></br>
              <span>• </span>
              <span>Support and autonomy to build your dream department</span>
              <br></br>
            </span>
            <div className="hr-job-opening-footer">
              <a
                href="https://forms.gle/tARvBEYprcRCQsph6"
                target="_blank"
                rel="noreferrer noopener"
                className="hr-job-opening-link1 button"
              >
                <span className="hr-job-opening-text121">
                  <span>Submit Application</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="hr-job-opening-container11">
          <div className="hr-job-opening-container12">
            <div className="hr-job-opening-container13">
              <Link to="/" className="hr-job-opening-navlink2">
                Nimble
              </Link>
            </div>
            <div className="hr-job-opening-container14">
              <span className="hr-job-opening-text124">About Us</span>
              <Link to="/careers" className="hr-job-opening-navlink3">
                Careers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HRJobOpening
