import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './internal-auditor-job-opening.css'

const InternalAuditorJobOpening = (props) => {
  return (
    <div className="internal-auditor-job-opening-container">
      <Helmet>
        <title>Internal-Auditor-Job-Opening - Nimble</title>
        <meta
          property="og:title"
          content="Internal-Auditor-Job-Opening - Nimble"
        />
      </Helmet>
      <div className="internal-auditor-job-opening-container01">
        <div className="internal-auditor-job-opening-container02">
          <div className="internal-auditor-job-opening-container03">
            <div className="internal-auditor-job-opening-container04">
              <Link to="/" className="internal-auditor-job-opening-navlink">
                Nimble
              </Link>
            </div>
            <div className="internal-auditor-job-opening-container05">
              <span className="internal-auditor-job-opening-text">
                About Us
              </span>
              <Link
                to="/careers"
                className="internal-auditor-job-opening-navlink1"
              >
                Careers
              </Link>
            </div>
          </div>
        </div>
        <div className="internal-auditor-job-opening-container06">
          <div className="internal-auditor-job-opening-container07">
            <div className="internal-auditor-job-opening-container08">
              <h1 className="internal-auditor-job-opening-text01">TAKE </h1>
              <h1 className="internal-auditor-job-opening-text02">THE </h1>
              <h1 className="internal-auditor-job-opening-text03">NEXT </h1>
              <h1 className="internal-auditor-job-opening-text04">STEP.</h1>
            </div>
            <h1 className="internal-auditor-job-opening-text05">
              <span>Join the Nimble Team</span>
              <br></br>
            </h1>
          </div>
          <div className="internal-auditor-job-opening-container09">
            <h1 className="internal-auditor-job-opening-text08">
              <span className="internal-auditor-job-opening-text09">
                Internal Auditor
              </span>
              <br></br>
            </h1>
            <div className="internal-auditor-job-opening-container10">
              <span className="internal-auditor-job-opening-text11">
                Onsite | Rodney Bay, St. Lucia
              </span>
              <a
                href="https://forms.gle/fUJvaQTXbgPgCwiw6"
                target="_blank"
                rel="noreferrer noopener"
                className="internal-auditor-job-opening-link button"
              >
                <span className="internal-auditor-job-opening-text12">
                  <span>Submit Application</span>
                  <br></br>
                </span>
              </a>
            </div>
            <h1 className="internal-auditor-job-opening-text15">About Us</h1>
            <span className="internal-auditor-job-opening-text16">
              Nimble is a forward-thinking company in the process of applying
              for a banking license, and we are excited about the transformative
              journey ahead. We believe in the power of adaptability,
              technology, and exceptional service delivery. We are looking for a
              passionate and experienced Human Resources Manager to join our
              team and build our HR department from the ground up. This is a
              unique opportunity to create your dream department and be a
              pivotal part of our growth
            </span>
            <h1 className="internal-auditor-job-opening-text17">Note:</h1>
            <span className="internal-auditor-job-opening-text18">
              <span>
                This job offer is subject to ECCB licensing. The successful
                candidate will play a crucial role in helping us achieve our
                banking license and will be a key member of our leadership team.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                If you are a detail-oriented and analytical professional looking
                for a unique opportunity to shape the future of internal
                auditing at a dynamic company, we would love to hear from you.
                Join us at Nimble and be part of an exciting journey of growth
                and innovation.
              </span>
              <br></br>
              <br></br>
              <span>
                Your application is completely confidential. However, we will
                have to share your information with the Eastern Caribbean
                Central Bank.
              </span>
            </span>
            <h1 className="internal-auditor-job-opening-text26">Role:</h1>
            <span className="internal-auditor-job-opening-text27">
              As the Internal Auditor at Nimble, you will be responsible for
              evaluating and improving the effectiveness of risk management,
              control, and governance processes. You will play a critical role
              in ensuring that our operations are efficient, compliant, and
              aligned with our business objectives. This position requires a
              detail-oriented and analytical individual who is comfortable with
              technology and willing to learn and use Google Workspace
              effectively. You must be enthusiastically willing to integrate
              technology into work processes.
            </span>
            <h1 className="internal-auditor-job-opening-text28">
              Responsibilities:
            </h1>
            <span className="internal-auditor-job-opening-text29">
              <span>
                • Conduct risk assessments and identify areas of improvement in
                financial and operational processes
              </span>
              <br></br>
              <span>
                • Develop and implement internal audit plans and procedures
              </span>
              <br></br>
              <span>• </span>
              <span>
                Perform audits of financial statements, internal controls, and
                compliance with laws and regulations
              </span>
              <br></br>
              <span>
                • Evaluate the effectiveness of risk management and governance
                processes 
              </span>
              <br></br>
              <span>
                • Prepare detailed reports on audit findings and recommend
                corrective actions
              </span>
              <br></br>
              <span>
                • Monitor the implementation of corrective actions to ensure
                they address the identified issues
              </span>
              <br></br>
              <span>
                • Collaborate with management to develop strategies for
                improving internal controls and operational efficiency
              </span>
              <br></br>
              <span>
                • Stay up-to-date with industry trends, regulations, and best
                practices in internal auditing
              </span>
              <br></br>
              <span>
                • Provide training and guidance to staff on internal audit
                procedures and compliance requirements
              </span>
              <br></br>
            </span>
            <h1 className="internal-auditor-job-opening-text49">
              Requirements:
            </h1>
            <span className="internal-auditor-job-opening-text50">
              <span>
                • Proven working experience as an Internal Auditor or Senior
                Auditor 
              </span>
              <br></br>
              <span>
                • Advanced computer skills on MS Office (or similar), accounting
                software, and databases
              </span>
              <br></br>
              <span>
                • Ability to manipulate large amounts of data and compile
                detailed reports
              </span>
              <br></br>
              <span>
                • Proven knowledge of auditing standards and procedures, laws,
                rules, and regulations
              </span>
              <br></br>
              <span>
                • High attention to detail and excellent analytical skills
              </span>
              <br></br>
              <span>• Sound independent judgment</span>
              <br></br>
              <span>• Excellent written and verbal communication skills</span>
              <br></br>
              <span>
                • Comfortable with technology and computer usage or willing to
                enthusiastically learn, particularly Google Workspace
              </span>
              <br></br>
              <span>
                • High self-accountability and the ability to work independently
                without micromanagement
              </span>
              <br></br>
              <span>
                • Willingness to relocate to St. Lucia or currently reside in
                St. Lucia is a plus but not mandatory
              </span>
              <br></br>
              <span>
                • Candidates with 10+ years of direct banking experience will be
                preferential
              </span>
              <br></br>
            </span>
            <h1 className="internal-auditor-job-opening-text73">Salary:</h1>
            <span className="internal-auditor-job-opening-text74">
              <span>• $150,000 - $180,000 XCD base</span>
              <br></br>
              <span>• Plus s</span>
              <span>igning bonus</span>
              <br></br>
            </span>
            <h1 className="internal-auditor-job-opening-text80">Benefits:</h1>
            <span className="internal-auditor-job-opening-text81">
              <span>• </span>
              <span>Flexible PTO</span>
              <br></br>
              <span>• </span>
              <span>Flexibility to work from home</span>
              <br></br>
              <span>• </span>
              <span>Health, Vision, Dental Insurance</span>
              <br></br>
              <span>• </span>
              <span>Support and autonomy to build your dream department</span>
              <br></br>
            </span>
            <div className="internal-auditor-job-opening-footer">
              <a
                href="https://forms.gle/fUJvaQTXbgPgCwiw6"
                target="_blank"
                rel="noreferrer noopener"
                className="internal-auditor-job-opening-link1 button"
              >
                <span className="internal-auditor-job-opening-text94">
                  <span>Submit Application</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="internal-auditor-job-opening-container11">
          <div className="internal-auditor-job-opening-container12">
            <div className="internal-auditor-job-opening-container13">
              <Link to="/" className="internal-auditor-job-opening-navlink2">
                Nimble
              </Link>
            </div>
            <div className="internal-auditor-job-opening-container14">
              <span className="internal-auditor-job-opening-text97">
                About Us
              </span>
              <Link
                to="/careers"
                className="internal-auditor-job-opening-navlink3"
              >
                Careers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalAuditorJobOpening
